<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import * as Questions from "@/services/questions.js";
import moment from "moment";
import appConfig from "@/app.config";
import Table from "@/components/elements/Tables";
export default {
  page: {
    title: "Not Answerd Questions",
    meta: [{ name: "Questions", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Table },
  created() {
    Questions.GetAllNotAnswerd().then((data) => {
      this.tableData = data;
      // console.log(this.tableData);
    });
  },

  data() {
    return {
      tableData: [],
      title: this.$t("not_answerd_questions"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.questions.text"),
        },
        {
          text: this.$t("not_answerd"),
          active: true,
        },
      ],

      filterOn: [],
      sortBy: "createdAt",

      fields: [
        { label: this.$t("client_name"), key: "clientName", sortable: false },
        {
          label: this.$t("title"),
          key: "QuestionTitle.",
          sortable: false,
        },

        {
          label: this.$t("date"),
          key: "createdAt",
          formatter: (value) => {
            return moment(value).calendar();
          },
          sortable: true,
        },
      ],
    };
  },
  computed: {
    FlattentableData() {
      return this.tableData.map((Question) => {
        // Flatten the object structure and return a new object
        return {
          clientName:!Question?.questionData?.isAnonymous?  Question.clientName||'ــــــــ' : 'ــــــــ',
          clientId:Question.clientId,
          QuestionID: Question.id,
          QuestionTitle: Question.questionData.QuestionTitle,
          QuestionStatus: Question.questionData.question_statue,
          createdAt: Question.createdAt,
          isAnonymous:Question?.questionData?.isAnonymous
          // Include other properties you want to flatten
        };
      });
    },
  },
  methods: {
    rowClicked(item) {
      console.log(item);
      this.$router.push({ path: "/question/" + item.QuestionID });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Table
      :tableData="FlattentableData"
      :fields="fields"
      :sortBy="sortBy"
      :filterOn="filterOn"
      @rowClicked="rowClicked"
      :hasAnnon="true"
      :filter-keys="['clientName']"
    />
  </Layout>
</template>
